var config = {
  content: `😋 Tasty Cuisine Chinese Restaurant, Chicago's hidden gem,
                    located in the Northwest suburbs of Chicago.
                    Specializes in modern classic Cantonese and ChaoZhou flavors located in Des Plaines,
                    down the street from Mitsuwa Marketplace.
                    We have one location.
                    Tasty offers catering for weddings, banquets, corporate events and other functions.
                    著名粵菜城 芝加哥｜潮州菜 | 潮汕菜 | 廣東粵菜 | 港式粵菜 | 台灣菜 | 滷水鴨.`,
  keywords: `Tasty Cuisine Chinese menu,
            Tasty Cuisine Chinese item,
            Tasty Cuisine Chinese prices,
            Tasty Cuisine Chinese Restaurant,
            Chicago IL Restaurant,
            deem sum,
            dimsum,
            deemsum,
            asian food,
            finger food,
            cantonese food,
            chinese food,
            chinese restaurant,
            chinese dining,
            cantonese cuisine,
            restaurant,
            japanese,
            habichi,
            vegetarian,
            food,
            chinese food,
            cater,
            catering,
            hot sauce,
            peking duck,
            spring roll,
            egg roll,
            take out,
            delivery,
            wedding banquets,
            banquets,
            chinese banquets,
            chow mein,
            sauce,
            corporate,
            functions,
            events,
            private,
            cocktails,
            receptions,
            parties,
            event,
            facility,
            specialty,
            cuisine,
            chili,
            chili pepper,
            chile oil,
            appetizers,
            delicacies,
            traditional chinese food,
            traditional chinese cuisine,
            traditional dim sum,
            private chinese dining.`
}

// module.exports = {
//   content: 'ddd',
//   keywords: 'kkkk'
// }

module.exports = config
