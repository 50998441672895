// const i18n = require('nuxt-i18n')
// import Vue from 'vue'

// import $nuxt from 'nuxt-edge'

// const $t = Vue.i18n.tc

// import i18n from 'nuxt-i18n/types'

const SiteMap = {
  siteMap: [
    {
      title: 'Exotic Drink',
      key: 'exoticdrink',
      description: null
    },
    {
      title: 'Cocktails',
      key: 'cocktail',
      description: null
    },
    {
      title: 'House Wine',
      key: 'housewine',
      description: null
    },
    {
      title: 'Oriental Wine',
      key: 'orientalwine',
      description: null
    },
    {
      title: 'Premium Liquor',
      key: 'premiumliquor',
      description: ['Double Shots']
    },
    {
      title: 'Boba Tea',
      key: 'bubbletea',
      description: null
    },
    {
      title: 'Appetizer - 前菜',
      key: 'appetizer',
      description: null
    },
    {
      title: 'Soup - 湯羹',
      key: 'soup',
      description: null
    },
    {
      title: 'BBQ - 燒臘',
      key: 'bbq',
      description: null
    },
    {
      title: 'Signature - 特色名菜',
      key: 'signature',
      description: null
    },
    {
      title: 'Poultry - 雞肉',
      key: 'poultry',
      description: null
    },
    {
      title: 'Beef - 牛肉',
      key: 'beef',
      description: null
    },
    {
      title: 'Pork - 豬肉',
      key: 'pork',
      description: null
    },
    {
      title: 'Vegetables - 素菜',
      key: 'vegetables',
      description: null
    },
    {
      title: 'Seafood - 海鮮',
      key: 'seafood',
      description: null
    },
    {
      title: 'Tofu - 豆腐',
      key: 'tofu',
      description: null
    },
    {
      title: 'Noodle Soup - 湯麵',
      key: 'noodlesoup',
      description: null
    },
    {
      title: 'LoMein - 撈麵',
      key: 'lomein',
      description: null
    },
    {
      title: 'Fried Rice - 炒飯',
      key: 'friedrice',
      description: null
    },
    {
      title: 'ChowFun ChowMein - 炒粉炒麵',
      key: 'chaofenmian'
    },
    {
      title: "Today's Special",
      key: 'setmeal',
      description: [
        'Lunch Special Hours: 11am-2pm',
        'Served with one crabmeat raggon and one egg rolll, chicken fried rice or steamed rice',
        'LoMein does not come with rice'
      ]
    }
  ]
}

module.exports = SiteMap
